import { Footer as CommonFooter, FooterMenu } from "@akte-mord/common";
import { useTheme } from "@mui/material";

const MENUS: FooterMenu[] = [
  {
    title: "Jeux de détective",
    items: [
      { label: "Les Couleurs de l’oubli", route: "/jeux-de-detective/les-couleurs-de-l-oubli" },
      { label: "Solution", route: "/solution" },
      { label: "Aide", route: "/aide" },
      // { label: "Demandes des distributeurs", route: "/demandes-des-distributeurs" },
    ],
  },
  // {
  //   title: "Médias sociaux",
  //   items: [
  //     {
  //       label: "Suivez-nous sur Facebook",
  //       route: "https://www.facebook.com/dieaktemord",
  //       external: true,
  //     },
  //     {
  //       label: "Suivez-nous sur Instagram",
  //       route: "https://www.instagram.com/aktemord",
  //       external: true,
  //     },
  //   ],
  // },
  {
    title: "Informations",
    items: [
      { label: "Politique de confidentialité", route: "/politique-de-confidentialite" },
      { label: "Mentions légales", route: "/mentions-legales" },
    ],
  },
];

export const Footer: React.FC<{ light?: boolean }> = ({ light = false }) => {
  const theme = useTheme();

  return (
    <CommonFooter
      showPayments={false}
      sx={light ? { background: theme.palette.background.default } : {}}
      menus={MENUS}
    />
  );
};
